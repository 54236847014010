import React, { createContext, useState, useContext } from "react";
import eng from "../../Language/eng.json";
import tam from "../../Language/tam.json";

const languageData = {
  eng,
  tam,
};

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("tam");

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider
      value={{
        selectedLanguage: language,
        setSelectedLanguage: changeLanguage,
        languageData: languageData?.[language],
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
