export const throwError = (code, message) => {
  let instance = new Error(code, message);
  instance.code = code;
  instance.message = message;
  return instance;
};

export function errorHandler(error) {
  let result = {};
  switch (error.code) {
    case "auth":
      result.code = error.code;
      result.message = error.message;
      break;
    case "auth/timeout":
      result.code = error.code;
      result.message = {
        tam: "கோரிக்கை நேர முடிந்துவிட்டது. பின்னர் மீண்டும் முயற்சிக்கவும்.",
        eng: "Request timed out. Please try again later",
      };
      break;
    case "auth/invalid-credential":
      result.code = error.code;
      result.message = {
        tam: "தவறான OTP. உங்கள் OTPஐ சரிபார்க்கவும்.",
        eng: "Invalid credentials. Please check your OTP.",
      };
      break;
    case "auth/invalid-verification-code":
      result.code = error.code;
      result.message = {
        tam: "தவறான OTP. உங்கள் OTPஐ சரிபார்க்கவும்.",
        eng: "Invalid credentials. Please check your OTP.",
      };
      break;
    case "auth/invalid-verification-id":
      result.code = error.code;
      result.message = {
        tam: "தவறான OTP. உங்கள் OTPஐ சரிபார்க்கவும்.",
        eng: "Invalid credentials. Please check your OTP.",
      };
      break;
    case "auth/captcha-check-failed":
      result.code = error.code;
      result.message = {
        tam: "கேப்சா மதிப்பீடு தோல்வி அடைந்தது. தயவுசெய்து மீண்டும் முயற்சிக்கவும்.",
        eng: "Captcha assessment failed. Please try again.",
      };
      break;
    case "auth/invalid-phone-number":
    case "auth/missing-phone-number":
      result.code = error.code;
      result.message = {
        tam: "தயவுசெய்து ஒரு செல்லுபடியான தொலைபேசி எண்ணை உள்ளிடவும்.",
        eng: "Please enter a valid phone number.",
      };
      break;

    case "auth/id-token-expired":
    case "auth/id-token-revoked":
    case "auth/invalid-user-token":
    case "auth/user-token-expired":
      result.code = error.code;
      result.message = {
        tam: "அமர்வு முடிந்துவிட்டது. தொடர மீண்டும் உள்நுழைக.",
        eng: "Session Expired. Please login to continue.",
      };
      break;
    case "auth/network-request-failed":
      result.code = error.code;
      result.message = {
        tam: "இணையக் கோரிக்கை தோல்வியடைந்தது. பின்னர் மீண்டும் முயற்சிக்கவும்.",
        eng: "Connectivity error. Please try again later.",
      };
      break;
    case "auth/requires-recent-login":
      result.code = error.code;
      result.message = {
        tam: "அமர்வு காலம் மிகவும் நீண்டது. தயவுசெய்து மீண்டும் உள்நுழைக.",
        eng: "Session duration too long. Please re-login.",
      };
      break;
    case "auth/quota-exceeded":
    case "auth/too-many-requests":
      result.code = error.code;
      result.message = {
        tam: "பயன்பாட்டு வரம்பு மீறப்பட்டுள்ளது. தயவுசெய்து பின்னர் முயற்சிக்கவும்.",
        eng: "Usage limit exceeded. Please try again later.",
      };
      break;
    case "auth/web-storage-unsupported":
      result.code = error.code;
      result.message = {
        tam: "ஆதரிக்கப்படாத வலை உலாவி.",
        eng: "Unsupported web browser.",
      };
      break;

    //input error
    case "input/image-size-exceeded":
      result.code = error.code;
      result.message = {
        tam: "படத்தின் அளவு 2MB க்கும் குறைவாக இருக்க வேண்டும்.",
        eng: "Image size should be less than 2MB.",
      };
      break;
    case "input/file-size-exceeded":
      result.code = error.code;
      result.message = {
        tam: "ஆவணத்தின் அளவு 5MB-க்கு குறைவாக இருக்க வேண்டும்.",
        eng: "Document size should be less than 5MB.",
      };
      break;

    //custom error from frontend
    case "custom":
      result.code = error.code;
      result.message = error.message;
      break;

    //firestore database error codes
    case "data-loss":
      result.code = `database/${error.code}`;
      result.message = {
        tam: "தரவுகள் சிதறியுள்ளன. தயவுசெய்து பின்னர் முயற்சிக்கவும்.",
        eng: "Data corrupted. Please try again later.",
      };
      break;

    case "search":
      result.code = `database/${error.code}`;
      result.message = error.message;
      break;

    case "cancelled":
    case "deadline-exceeded":
    case "not-found":
    case "already-exists":
    case "permission-denied":
    case "resource-exhausted":
    case "failed-precondition":
    case "aborted":
    case "out-of-range":
    case "unimplemented":
    case "internal":
    case "unavailable":
    case "invalid-argument":
      result.code = `database/${error.code}`;
      result.message = {
        tam: "ஏதோ தவறாகிவிட்டது. பின்னர் மீண்டும் முயற்சிக்கவும்.",
        eng: "Something went wrong. Please try again later.",
      };
      break;

    //form validation errors
    case "input":
      result.code = `input`;
      result.message = error.message;
      break;

    // Storage errors
    case "unauthenticated":
      result.code = `storage/${error.code}`;
      result.message = {
        tam: "அமர்வு முடிந்துவிட்டது. தொடர மீண்டும் உள்நுழைக.",
        eng: "Session Expired. Please login to continue.",
      };
      break;
    case "canceled":
      result.code = `storage/${error.code}`;
      result.message = {
        tam: "செயல்முறை ரத்து செய்யப்பட்டது.",
        eng: "Process Cancelled.",
      };
      break;

    case "object-not-found":
    case "bucket-not-found":
    case "unauthorized":
    case "retry-limit-exceeded":
    case "invalid-event-name":
    case "invalid-url":
    case "server-file-wrong-size":
      result.code = `storage/${error.code}`;
      result.message = {
        tam: "ஏதோ தவறாகிவிட்டது. பின்னர் மீண்டும் முயற்சிக்கவும்.",
        eng: "Something went wrong. Please try again later.",
      };
      break;
    case "400":
      result.code = `api/${error.code}`;
      result.message = error.message;
      break;
    default:
      if (
        error.code === undefined ||
        !error.code.includes("auth") ||
        !error.code.includes("database") ||
        !error.code.includes("input") ||
        !error.code.includes("storage")
      ) {
        result.code = `unknown/${error.code}`;
      } else {
        result.code = error.code;
      }
      result.message = {
        tam: "ஏதோ தவறாகிவிட்டது. பின்னர் மீண்டும் முயற்சிக்கவும்.",
        eng: "Something went wrong. Please try again later.",
      };
      break;
  }
  return result;
}
